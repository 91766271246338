.control
{
    gap:             20px;
    display:         flex;
    flex-flow:       row nowrap;
    justify-content: flex-end;
    margin-top:      20px;

    @media (width >= 867px)
    {
        width:        800px;
        max-width:    calc(100% - 320px);
        margin-right: 320px;
    }
}
