@mixin button($color)
{
    color:      $color;
    font-size:  20px;
    border:     none;
    padding:    0;
    background: none;
}

@mixin close($color)
{
    position:   absolute;
    top:        10px;
    right:      10px;

    @include button($color);
}
