@import '../../styles/import';

.settings
{
    position:   fixed;
    top:        50%;
    left:       50%;
    width:      100%;
    height:     100%;
    overflow-y: auto;
    border:     1px solid $secondaryColor;
    padding:    40px 20px 10px 20px;
    transform:  translate(-50%, -50%);

    &[open]
    {
        gap:       20px;
        display:   flex;
        flex-flow: row nowrap;
    }

    .buttons
    {
        gap:       20px;
        display:   flex;
        flex-flow: row nowrap;

        .keys
        {
            font-size: 16px;
        }
    }

    .shortcuts,
    .holds
    {
        position:   relative;
        margin-top: 20px;
        max-width:  100%;
        gap:        20px;
        display:    flex;
        flex-flow:  row wrap;

        > div
        {
            flex: 0 0 100px;
        }
    }

    .navigation
    {
        flex:        0;
        gap:         10px;
        display:     flex;
        flex-flow:   column;
        align-items: flex-start;

        > button
        {
            align-self:  inherit;
            white-space: nowrap;

            &.active
            {
                color:            $primaryBackground;
                background-color: $secondaryColor;
            }
        }
    }

    .subsettingsContainer
    {
        flex:     1;
        position: relative;

        .subsettings
        {
            width:  100%;
            border: none;
        }
    }
}
