@import 'styles/import';

*
{
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}

body
{
    color:                   $primaryColor;
    background-color:        $primaryBackground;
    font-family:             -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                             sans-serif;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:global
{
    .hg-button
    {
        color:            $primaryColor;
        background-color: $primaryBackground !important;
    }

    .highlight
    {
        color:            $primaryBackground;
        background-color: $secondaryColor !important;
    }

    .hold
    {
        color:            $primaryBackground;
        background-color: $secondaryBackground !important;
    }

    #root
    {
        display:     flex;
        flex-flow:   column;
        align-items: center;
    }
}

.main
{
    width:     1440px;
    max-width: 100%;

    > *
    {
        width: 100%;
    }
}

button
{
    cursor:           pointer;
    padding:          10px 20px;
    align-self:       center;
    border:           1px solid $secondaryColor;
    border-radius:    5px;
    background-color: $primaryBackground;

    &:hover
    {
        color:            $primaryBackground;
        background-color: $secondaryColor;
    }
}

.close
{
    @include close($secondaryColor);
}
