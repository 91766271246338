@import '../../styles/import';

.shortcut
{
    position:        relative;
    height:          100%;
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: center;
    aspect-ratio:    1 / 1;
    user-select:     none;
    border:          1px solid $secondaryColor;
    border-radius:   5px;

    .delete
    {
        @include button($primaryBackground);

        position:         absolute;
        z-index:          1;
        top:              0;
        left:             0;
        width:            100%;
        height:           100%;
        opacity:          0;
        background-color: $secondaryColor;

        &:hover
        {
            opacity: 1;
        }
    }

    .key
    {
        font-size:   40px;
        font-weight: bold;

        &.shift
        {
            position: absolute;
            right:    5px;
            bottom:   5px;
        }
    }
}
