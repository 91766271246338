.timer
{
    display:         flex;
    align-items:     center;
    justify-content: center;
    padding-left:    50px;
    padding-right:   50px;

    .time
    {
        flex:        1;
        display:     flex;
        flex-flow:   column;
        align-items: center;
        font-size:   40px;

        span
        {
            display:   block;
            font-size: 20px;
        }
    }
}
