@import '../../styles/import';

.quests
{
    position:    relative;
    gap:         20px;
    display:     flex;
    flex-flow:   row nowrap;
    align-items: stretch;
    height:      100px;
    overflow:    hidden;

    &::after
    {
        content:    '';
        position:   absolute;
        top:        0;
        left:       100px;
        width:      calc(100% - 100px);
        height:     100%;
        background: linear-gradient(90deg, transparent 0%, $primaryBackground 100%);
    }

    > div
    {
        &:first-child
        {
            color:            $primaryBackground;
            background-color: $secondaryColor;
        }

        @for $i from 1 through 6
        {
            &:nth-child(#{$i})
            {
                margin-left: ($i - 1) * 5px;
            }
        }
    }
}
