.home
{
    display:     flex;
    flex-flow:   column;
    align-items: center;

    .quest
    {
        width:     100%;
        display:   flex;
        flex-flow: row nowrap;
        overflow:  hidden;

        > div
        {
            flex: 0 0 50%;

            &:nth-child(1),
            &:nth-child(2)
            {
                flex: 1 0 25%;
            }
        }
    }

    .homeControl
    {
        width:       100%;
        display:     flex;
        flex-flow:   column;
        align-items: center;

        @media (width >= 867px)
        {
            align-items: flex-start;
        }
    }

    .keyboardContainer
    {
        width:     100%;
        gap:       20px;
        display:   flex;
        flex-flow: column;

        @media (width >= 867px)
        {
            flex-flow: row nowrap;
        }

        .highscore
        {
            flex:      1;
            gap:       10px;
            display:   flex;
            flex-flow: column;
        }
    }
}
