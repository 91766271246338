.highscore
{
    padding-top: 20px;
    min-height:  300px;

    @media (width >= 867px)
    {
        min-height: inherit;
        min-width:  300px;
    }
}
