@import '../../../styles/import';

.alert
{
    position:         relative;
    color:            $primaryBackground;
    border:           1px solid;
    font-size:        20px;
    padding:          20px 45px 20px 30px;
    background-color: $errorColor;

    &.error
    {
        border-color: $errorColor;
    }

    button
    {
        @include close($primaryBackground);
    }
}
