@import '../../styles/import';

.hold
{
    position:         relative;
    flex:             0 0 100px;
    display:          flex;
    flex-flow:        column;
    align-items:      center;
    justify-content:  center;
    font-size:        50px;
    font-weight:      bold;
    border:           1px solid $secondaryBackground;
    border-radius:    5px;
    color:            $primaryBackground;
    background-color: $secondaryBackground;

    &.finished
    {
        border:           1px solid $secondaryColor;
        background-color: $secondaryColor;
    }

    span
    {
        font-size: 15px;
    }

    .start
    {
        position:  absolute;
        bottom:    10px;
        right:     10px;
        font-size: 15px;
    }
}
